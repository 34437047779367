import { AbstractModel } from "../abstract-model";
import { MinDTO } from "../min-dto";
import { MarketSeaProductsModel } from "./market-sea-products.model";
import {SeaPackModel} from "./sea-pack-model";



export class SeaModel extends AbstractModel {

    operationType: string;
    pieces: number;
    containerType: MinDTO;
    tonnage: number;
    kp: string;
    td: string = 'T';
    totalWeight: number = null;
    totalVolume: number = null;
    cwg: number = null;
    stackable: boolean = true;
    calculatedPieces: number;
    uselbInch: boolean = false;
    chargeableWeight: number;
    chargeableVolume: number;
    totalWm: number;
    containerCount: number;
    newPriceProduct: MarketSeaProductsModel;
    discounts: string[] = [];



    packs: SeaPackModel[] = [];
    // PRODUCT
    products: MarketSeaProductsModel[] = [];
    selectedProduct: MarketSeaProductsModel;
    
}
