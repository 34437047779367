import { Component, Injector, Input, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SeaSearchService } from 'src/app/services/sea/sea-search.service';
import { BaseFormComponentComponent } from 'src/app/shared/base-form-component/base-form-component.component';
import { SeaComponent } from '../sea.component';
import { DatePipe } from '@angular/common';
import { StepperService } from 'src/app/stepper.service';
import { SeaModel } from 'src/app/models/sea/sea-model';
import {SeaPackModel} from "../../../models/sea/sea-pack-model";
import {DecimalPipe} from "../../../shared/decimal.pipe";
import {DocViewerDialogComponent} from "../../../components/doc-viewer-dialog/doc-viewer-dialog.component";
import {DynamicDialogRef} from "primeng/dynamicdialog";


@Component({
  selector: 'app-sea-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [DecimalPipe],
  encapsulation: ViewEncapsulation.None
})
export class SeaSearchComponent extends BaseFormComponentComponent implements OnInit {

  @Input("model") model: SeaModel;
  nowDate = new Date();
  termsDialog: DynamicDialogRef;
    requiredPackFields = [
        {name: 'packing', type: 'string'},
        {name: 'pieces', type: 'number'},
        {name: 'height', type: 'number'},
        {name: 'width', type: 'number'},
        {name: 'lenght', type: 'number'},
        {name: 'perKg', type: 'number'}];
    requiredPackFieldsForT = [
        {name: 'pieces', type: 'number'},
        {name: 'totalVolume', type: 'number'},
        {name: 'totalWeight', type: 'number'}];

  constructor(protected injector: Injector,
    @Inject(SeaComponent) public parent: SeaComponent,
    private stepperService: StepperService, private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private router: Router, private seaSearchService: SeaSearchService) {
    super(injector)
   }

  ngOnInit(): void {
      this.model.products = null;
      this.model.selectedProduct = null;
      this.model.quoteNo = null;
      if(!this.model.packs || this.model?.packs?.length  == 0 ) this.addPack();

      this.calculateTotals();

      if (!this.model.kp) this.model.kp = 'F';

    /* p-calendar de dışında farklı bir component kullanılacak */
    if(this.model.readinessDate && !(this.model.readinessDate instanceof Date)) {
        this.model.readinessDate = new Date(`${String(this.model.readinessDate).substring(0,10).trim()}`);
    }

    this.parent.stepper.notShowReservation = false;




  }

    async search(form) {


        const valid = await this.isValid();

        if (!valid) return;

        const isEmpty = [];
        let totalWm = null;
        let calculatedPieces = null;

        if (this.model.operationType === 'FCL') {
            if (!this.model.containerCount || this.model.containerCount <= 0) {
                const pieceWarn = await this.translation.get('piecesWarn').toPromise();
                this.messageService.add({severity: 'warn', key: 'html-msg', summary: '', detail: pieceWarn});
                return;
            }
            if (!this.model.tonnage || this.model.tonnage <= 0) {
                const tonnageWarn = await this.translation.get('tonnageWarn').toPromise();
                this.messageService.add({severity: 'warn', key: 'html-msg', summary: '', detail: tonnageWarn});
                return;
            }
        } else {
            if (this.model?.td === 'D') {
                this.model.packs.forEach(p => {
                    this.requiredPackFields.map(field => {
                        if (field.type === 'string' && !p[field.name]) {
                            isEmpty.push(field.name);
                        }
                        if (field.type === 'number' && (!p[field.name] || p[field.name] === 0)) {
                            isEmpty.push(field.name);
                        }
                    });
                });

            } else {
                if (!this.model?.totalWeight || this.model?.totalWeight == 0) isEmpty.push('totalWeight');
                if (!this.model?.pieces || this.model?.pieces == 0) isEmpty.push('pieces');
                if (!this.model?.totalVolume || this.model?.totalVolume == 0) isEmpty.push('totalVolume');
            }
            if (isEmpty.length > 0) {
                const fieldsTranslated = [];
                for (let i = 0; i < isEmpty.length; i++) {
                    const val = await this.translation.get(isEmpty[i]).toPromise();
                    fieldsTranslated.push(val);
                }
                const fields = fieldsTranslated.filter((x, i, a) => a.indexOf(x) === i).join('<br/>');
      
                const enterValue = await this.translation.get('enterValues').toPromise();
                this.messageService.add({
                    severity: 'warn',
                    key: 'html-msg',
                    summary: '',
                    detail: enterValue + '<br/>' + fields
                });
                return;
            }
        }

        if(this.model.service && this.model.service[0] == 'D' && (!this.model.departureZip && !this.model.departureZipTxt)) {
            this.showMessage('warn', 'departureZipRequired');
            return;
        }
        if(this.model.service && this.model.service[this.model.service.length -1] == 'D' && (!this.model.arrivalZip && !this.model.arrivalZipTxt)) {
            this.showMessage('warn', 'arrivalZipRequired');
            return;
        }

        try {
            this.parent.productLoading = true;
            window.scroll({top: this.parent.initialOffsetTop, left: 0, behavior: 'smooth'});

            /* Todo:
               tarih payload da 1 gün geri gidiyor nedeni anlaşılarak düzeltme uygulanacak.
             */

            // daha önce bulunan ürün varsa siliniyor.
            this.model.products = [];
            totalWm = this.model.totalWm;
            calculatedPieces = this.model.calculatedPieces;
            this.model.id = null;

            localStorage.setItem('search', JSON.stringify(this.model))
             // Eğer mal bedeli 500.000 USD den büyük ise fiyat talep et çıkması için arama yaptırmıyoruz.
            if(this.model.goodPrice < 500000) {
                this.model = await this.seaSearchService.search(this.model).toPromise();
            }

            if(this.model.products) {
                this.model.products = this.model.products.sort((a, b) => {
                // Önce shipOwner bilgisine göre sıralama
                if (a.shipOwner === null && b.shipOwner !== null) return 1; // a shipOwner null ise sona at
                if (a.shipOwner !== null && b.shipOwner === null) return -1; // b shipOwner null ise sona at
                if (a.shipOwner !== null && b.shipOwner !== null) {
                    if (a.shipOwner < b.shipOwner) return -1;
                    if (a.shipOwner > b.shipOwner) return 1;
                }
                
                // Eğer shipOwner bilgileri aynı veya null ise freights.grandTotal'a göre sıralama
                const grandTotalA = a.freights && a.freights.grandTotal !== null ? a.freights.grandTotal : Infinity;
                const grandTotalB = b.freights && b.freights.grandTotal !== null ? b.freights.grandTotal : Infinity;
                
                return grandTotalA - grandTotalB;
                });
            }


            this.model.totalWm = totalWm;
            this.model.calculatedPieces = calculatedPieces
            this.parent.container.clear();
            this.parent.model = this.model;

            this.parent.productLoading = false;
            window.scroll({top: this.parent.initialOffsetTop, left: 0, behavior: 'smooth'});
            
            this.stepperService.changeCurrentStep(2);

            if(!this.parent.showOnlyComponent) {
                this.addQueryParam("page", "FYT")
                this.addQueryParam("op",  this.model.operationType)

                if(this.model.operationType && this.model.operationType == 'FCL') {
                    this.addQueryParam("con",  this.model.containerType.name.replace(' ', ''))
                }
                this.addQueryParam("type", `${this.model.departurePort.name.slice(0,3)}(${this.model.departureCountry.code})-(${this.model.arrivalCountry.code})${this.model.arrivalPort.name.slice(0,3)}`)
            }


        } catch (error){
            if (error == 'userBannedForManyRequest'){
                this.forceLogOut();
            }
            this.parent.productLoading = false;
        }

      

    }

    forceLogOut(){
        Object.keys(localStorage).filter(k => k != 'rememberMe' && k != 'lang' && k != 'uid' && k != 'efl-saved-consent' && k != 'efl-uuid').forEach(key => localStorage.removeItem(key))
        setTimeout(() => {
            this.router.navigate(['/home']);
        }, 4000);
    }

  async getSegment(portFieldName) {
      if (this.model.arrivalPort && this.model.departurePort) {
          if ((this.model.arrivalPort && this.model.departurePort) && this.model.arrivalPort.id == this.model.departurePort.id) {
              setTimeout(() => this.model[portFieldName] = null, 100);
              this.showMessage('warn', 'sameSeaPortWarn');
              return;
          }
      }
      if (this.model.arrivalPort && this.model.departurePort) {
      const segment = await this.seaSearchService.segment(this.model.departurePort.id, this.model.arrivalPort.id, this.model.departureCountry.id, this.model.arrivalCountry.id).toPromise();
      this.model.segment = segment;
      }

      const discounts = await this.seaSearchService.discount().toPromise();
      this.model.discounts = discounts;
    //   this.model.discounts = discounts;

  }

  onChangeService() {
    this.model.arrivalAddress = null;
    this.model.arrivalZip = null;
    this.model.departureAddress = null;
    this.model.departureZip = null;
  }


    onChangeTD(td: string) {
        this.model.containerType = null;
        this.model.containerCount = 0;
        this.model.td = td;
        this.model.packs = [];
        this.model.packingType = null;
        this.model.pieces = null;
        this.model.tonnage = 0;
        this.model.totalVolume = 0;
        this.model.totalWeight = 0;
        this.model.chargeableVolume = 0;
        this.model.chargeableWeight = 0;
        this.model.totalWm = 0;

        if(td == 'D') this.addPack();
    }

    addPack() {

        if(!this.model.packs) this.model.packs = [];
        this.model.packs.push(new SeaPackModel());
    }

    calculateTotals(event:any = null) {
      if (this.model.operationType === 'LCL'){

        if(this.model.td == 'T') {
            this.calculateChargeableWeight();
        }
        if(this.model.td == 'D') {

            //calculate total pieces
            this.model.calculatedPieces = 0;
            const pieces = this.model.packs.filter(f => f.pieces).map(f => f.pieces);
            if(pieces.length > 0)  this.model.calculatedPieces = pieces.reduce((a,b) => a + b);

            // calculate total weight
            this.model.totalWeight = 0;
            this.model.packs.forEach(p => {
                this.model.totalWeight +=  (p.pieces * (p.perKg | 0));
            })


            // calculate line volume & calculate line total weight
            this.model.packs.forEach(i => {
                i.totalWeight = (i.perKg | 0) * (i.pieces | 0);
                this.calculateVolume(i);
            })


            // calculate total volume
            this.model.totalVolume = 0;
            const volumes = this.model.packs.filter(f => f.lineVolume).map(f => f.lineVolume);
            if(volumes.length > 0)  this.model.totalVolume = volumes.reduce((a,b) => a + b);
            this.model.totalVolume = Number(Number(this.model.totalVolume).toFixed(2));

            this.model.pieces = this.model.calculatedPieces;
            this.calculateChargeableWeight();


            // calculate cwg
        }
      }
    }

    calculateVolume(pack) {
        if (pack.width !== null && pack.height !== null
            && pack.lenght !== null && pack.pieces !== null) {
            let multipiler: number;
            if (!this.model.uselbInch) {
                multipiler = 0.01;
            }else {
                multipiler = 0.0254;
            }
            let height = 0;

            height = pack.height * multipiler;

            const width = pack.width * multipiler;
            const length = pack.lenght * multipiler;
            pack.lineVolume = width * height * length * pack.pieces;
        }
    }

    calculateChargeableWeight() {
        if (this.model.totalVolume != null) {
            let calculatedWeight: number;
            calculatedWeight = this.model.totalVolume * 1000;
            if (this.model.totalWeight != null) {
                if (this.model.totalWeight > calculatedWeight) {
                    this.model.chargeableWeight = this.model.totalWeight;
                    this.model.chargeableVolume = this.model.totalWeight / 1000;
                    this.model.totalWm = this.model.chargeableVolume;
                } else {
                    this.model.chargeableWeight = calculatedWeight;
                    this.model.chargeableVolume = calculatedWeight / 1000;
                    this.model.totalWm = this.model.chargeableVolume;
                    this.model.totalWm = this.model.chargeableVolume;
                }
            } else {
                this.model.chargeableWeight = calculatedWeight;
                this.model.chargeableVolume = calculatedWeight / 1000;
                this.model.totalWm = this.model.chargeableVolume;
            }
        }

    }




    removePack(index: number) {
        this.model.packs.splice(index, 1);
        this.calculateTotals();
    }

    showUseTrms() {
        this.termsDialog = this.dialogService.open(DocViewerDialogComponent, { header: "view", showHeader: false, modal:true, closable: true,  width: '50%', height: '100%',  data: {filePath: `/assets/data/documents/terms_${this.translation.currentLang}.html`}});

    }


    onChangeKp(kp: any) {
      if (kp === 'F'){
          this.model.kp = kp ; this.model.operationType = 'FCL';
      }else {
          this.model.kp = kp ; this.model.operationType = 'LCL';
      }
      this.model.containerType = null;
      this.model.packs = [];
      this.model.packingType = null;
      this.model.pieces = null;
      this.model.containerCount = 0;
      this.model.tonnage = 0;
      this.model.totalVolume = null;
      this.model.totalWeight = null;
      this.model.chargeableVolume = null;
      this.model.chargeableWeight = null;
      this.model.totalWm = null;
      this.model.cwg = 0;
    }

    onSelectZipToAddress(e: any, elementRef: any, zipField = 'departureZip' ){
      if (zipField == 'departureZip') this.model.departureAddress = e.name;
      if (zipField == 'arrivalZip') this.model.arrivalAddress = e.name;
      this.onSelectZip(e, elementRef, zipField);
    }

    onSelectZip(e: any, elementRef: any, zipField = 'departureZip' ) {
      // Eğer diğer seçildi ise postCode boş oluyor.
      if(e.isOptionOther) {
        elementRef.autoComplete.inputEL.nativeElement.value = e.name.trim().split(' ')[0]
        this.model[zipField].name = e.name.trim().split(' ')[0];
        return
      };

      elementRef.autoComplete.inputEL.nativeElement.value = e['postCode']
      this.model[zipField].name = e['postCode']
    }

    onChangeDepartureCountry() {
      this.model.departurePort = null;
      this.model.departureZip = null;
      this.model.departureCity = null;
    }
    onChangeArrivalCountry() {
      this.model.arrivalPort = null;
      this.model.arrivalZip = null;
      this.model.arrivalCity = null;
    }

    deletePortByField(event: any ,field: string) {
        setTimeout(()=> {
            if (!event?.id){
                this.model[field] = null;
            }
        }, 100);
    }
}
