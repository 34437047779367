import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';
import { AuthGuard } from './services/auth-guard';

import { AppPublicComponent } from './app-public.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AirComponent } from './pages/air/air.component';
import { AppRegisterComponent } from './pages/app.register.component';
import { ListComponent } from './pages/list/list.component';
import { VerifyComponent } from './pages/verify.component';
import { SeaComponent } from './pages/sea/sea.component';
import { ProfileComponent } from './pages/user/profile/profile.component';
import { ForgotPasswordComponent } from './pages/user/forgot-password/forgot-password.component';
import {TruckComponent} from './pages/truck/truck.component';
import {LandingPageComponent} from "./pages/landing-page/landing-page.component";
import { TrackingeComponent } from './pages/tracking/tracking/tracking.component';
import { InvoiceComponent } from './pages/invoice/invoice.component';

@NgModule({
    imports: [
        RouterModule.forRoot(
            [{
                path: 'home', component: AppMainComponent,
                children: [
                    { path: '', component: DashboardComponent, data: {title: 'Dashboard', useSearch: true} },

                    { path: 'air', component: AirComponent, data: {title: 'Air', useSearch: true}, canActivate: [AuthGuard] },
                    { path: 'sea', component: SeaComponent, data: {title: 'Sea', useSearch: true}, canActivate: [AuthGuard] },
                    { path: 'truck', component: TruckComponent, data: {title: 'Truck', useSearch: true}, canActivate: [AuthGuard] },
                    { path: 'air/list/:type', component: ListComponent, data: {title: 'Air List', useSearch: true}, canActivate: [AuthGuard] },
                    { path: 'truck/list/:type', component: ListComponent, data: {title: 'Truck List', useSearch: true}, canActivate: [AuthGuard] },
                    { path: 'profile', component: ProfileComponent, data: {title: 'Profile', useSearch: true}, canActivate: [AuthGuard] },
                    { path: 'tracking/:container', component: TrackingeComponent, data: {title: 'Track And Tracking', useSearch: true}},

                ]
            },
            {path: 'document', loadChildren: () => import('../app/pages/upload-document/upload-document.module').then(m => m.UploadDocumentModule)},
            // {
            //     path: '', component: AppPublicComponent,
            //     children: [
            //     ]
            // },
            { path: ':uuid1/:uuid2', component: InvoiceComponent },
            { path: 'verify/:code', component: VerifyComponent },
            { path: 'user/reset/:code', component: ForgotPasswordComponent },
            { path: 'error', component: AppErrorComponent },
            { path: 'access', component: AppAccessdeniedComponent },
            { path: 'notfound', component: AppNotfoundComponent },
            { path: '', component: LandingPageComponent },
            //{ path: 'landpage', component: LandingPageComponent },
            { path: 'register', component: AppRegisterComponent},
            { path: '**', redirectTo: '/notfound' }, ], { scrollPositionRestoration: 'enabled' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
