import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { BaseService } from "../base.service";
import { ChangePasswordDTO } from "../../models/auth/change-password-dto.model";
import { environment } from "src/environments/environment";
import { UserModel } from "src/app/models/auth/user.model";
import { UserDocumentDTO } from "src/app/models/auth/user-document.model";
import { AuthenticationService } from "./authentication.service";

@Injectable()
export class UserService extends BaseService<any>{
    constructor(public httpClient: HttpClient, public datepipe: DatePipe, private authService: AuthenticationService) {
        super(
            'user',
            httpClient);
    }


    getByReferenceNo(referenceNo: string): Observable<UserModel> {
        let uri = new URL(environment.baseUrl + `/user/getByReferenceNo`).updateQueryParam({referenceNo})

        return this.httpClient.get<UserModel>(uri.toString(),  { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }
    
    user(): Observable<UserModel> {
        let uri = new URL(environment.baseUrl + `/user`)

        return this.httpClient.get<UserModel>(uri.toString(),  { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }
    
    updateUser(user: UserModel): Observable<UserModel> {
        let uri = new URL(environment.baseUrl + `/user`)
        return this.httpClient.post<UserModel>(uri.toString(), user,  { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }
    setLocale(locale: String): Observable<String> {
        let uri = new URL(environment.baseUrl + `/user/locale`).updateQueryParam({locale})   
        return this.httpClient.get<String>(uri.toString(), { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }
    
    upload(userDocumentDTO: UserDocumentDTO, docTypeName: string): Observable<UserDocumentDTO> {  
        let uri = new URL(environment.baseUrl + `/user/upload`).updateQueryParam({docTypeName})   

        let file = new File([userDocumentDTO.file], userDocumentDTO.fileName);
        const formData: FormData = new FormData();
        formData.append('data', file);

       
         return this.httpClient.post<UserDocumentDTO>(uri.toString(), formData,  { withCredentials: false, headers:  this.httpHeaders('multipart/form-data') })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    uploadAvatar(userDocumentDTO: UserDocumentDTO): Observable<UserDocumentDTO> {  
        let uri = new URL(environment.baseUrl + `/user/avatarUpload`);   

        let file = new File([userDocumentDTO.file], userDocumentDTO.fileName);
        const formData: FormData = new FormData();
        formData.append('data', file);

       
         return this.httpClient.post<UserDocumentDTO>(uri.toString(), formData,  { withCredentials: false, headers:  this.httpHeaders('multipart/form-data') })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }
    


    avatar(): Observable<UserDocumentDTO> {
        let uri = new URL(environment.baseUrl + `/user/avatar`)

        return this.httpClient.get<UserDocumentDTO>(uri.toString(),  { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    uploads(signature: UserDocumentDTO = null, invoice: UserDocumentDTO = null, trade: UserDocumentDTO = null): Observable<UserDocumentDTO> {  
        let uri = new URL(environment.baseUrl + `/user/uploads`)
        const formData: FormData = new FormData();

        if(signature) {
            let file = new File([signature.file], signature.fileName);
            formData.append('signature', file);
        }
        
        if(invoice) {
            let file = new File([invoice.file], invoice.fileName);
            formData.append('invoice', file);
        }

        if(trade) {
            let file = new File([trade.file], trade.fileName);
            formData.append('trade', file);
        }
    
    
       
         return this.httpClient.post<UserDocumentDTO>(uri.toString(), formData,  { withCredentials: false, headers:  this.httpHeaders('multipart/form-data') })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    getCompany() {
        if(!localStorage.getItem('user')) return;
        return JSON.parse(localStorage.getItem('user'))["company"] || null;
    }

  
    geDigitalUrl(reference: string, contentType: string, fileName: string ): string {
        const token = this.authService.getToken();
        let uri = new URL(environment.baseUrl + `/user/geDigitalFile`).updateQueryParam({reference, contentType, fileName, token})
        return uri.toString()
    }

    getDigitalFile(reference: string, contentType: string,fileName:string): Observable<any> {
        let uri = new URL(environment.baseUrl + `/user/geDigitalFile`).updateQueryParam({reference, contentType, fileName})

        return this.httpClient.get<any>(uri.toString(),  { withCredentials: false, headers: this.httpHeaders(),  responseType: 'blob' as 'json'  })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }
    getDocByteForTrackAndTracking(reference: string, contentType: string,fileName:string): Observable<any> {
        let uri = new URL(environment.baseUrl + `/user/getDocByteForTrackAndTracking`).updateQueryParam({reference, contentType, fileName})

        return this.httpClient.get<any>(uri.toString(),  { withCredentials: false, headers: this.httpHeaders(),  responseType: 'blob' as 'json'  })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }
    
    

    
    getDigitalUrlFromCache(): string {
        const token = this.authService.getToken();
        const uid = this.authService.getUid();
        let uri = new URL(environment.baseUrl + `/user/getCacheDocument`).updateQueryParam({token, uid})
        return uri.toString()
    }
    setCacheDocument(document: UserDocumentDTO): Observable<any> {
        let uri = new URL(environment.baseUrl + `/user/setCacheDocument`)
        return this.httpClient.post<UserDocumentDTO>(uri.toString(), document,  { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

}
