import { Component, Output, EventEmitter, Input, HostListener, ElementRef } from '@angular/core';

@Component({
  selector: 'app-invoice-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent {
  @Input() popup: boolean = false;
  @Input() data: any = {};

  @Output() togglePopup = new EventEmitter();
  @Output() openPopup = new EventEmitter();
  @Output() closePopup = new EventEmitter();
  @Output() download = new EventEmitter();

  constructor(
    private eRef: ElementRef,
  ) {}

  // ngAfterViewInit() {
  //   if (
  //     this.data.fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
  //     this.data.fileType === 'application/msword'
  //   ) {
  //     this.renderDocx(this.data.file);
  //   }
  // }

  // renderDocx(fileUrl: string) {
  //   fetch(fileUrl)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       const container = this.eRef.nativeElement.querySelector('#docxViewer');
  //       renderAsync(blob, container);
  //     })
  //     .catch((error) => {
  //       console.error('Error rendering docx:', error);
  //     });
  // }

  _togglePopup() {
    this.togglePopup.emit();
  }

  _openPopup() {
    this.openPopup.emit();
  }

  _closePopup() {
    this.closePopup.emit();
  }

  _download() {
    this.download.emit();
  }
}
