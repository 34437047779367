import { Component, OnDestroy, OnInit, ComponentFactoryResolver, ViewContainerRef, ViewChild } from '@angular/core';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { Subscription } from 'rxjs';
import { ChangePasswordComponent } from './pages/user/change-password/change-password.component';
import { Router } from '@angular/router';
import { NotificationsComponent } from './pages/user/notifications/notifications.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { NotificationsModel } from './models/notifications.model';
import { NotificationsService } from './services/notifications/notifications.service';
import { readNotify } from './pages/user/notifications/notifications.component';
import { OverlayPanel } from 'primeng/overlaypanel';
import { TrackingeComponent } from './pages/tracking/tracking/tracking.component';
import { TranslateService } from '@ngx-translate/core';
import { ChangeLanguageService } from './services/change-language.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styles: [`
       ::ng-deep .layout-topbar-wrapper .p-badge-no-gutter {
        background: red !important;
       }
        .ml-2 {
        margin-left: 0.5rem;
        }
        .mb-2 {
        margin-bottom: 0.5rem;
        }
        .ui-overlaypanel {
        right: 0 !important; /* Overlay'in sağa yaslanmasını sağlar */
        }
        ::ng-deep .tracking-selection .p-dropdown {
            width: 100% !important;
            border-radius: 0px !important;
        }
        ::ng-deep .tracking-selection .p-dropdown-label {
           color: #000;
        }
        ::ng-deep .tracking-selection .pi-chevron-down:before {
            color: #000;
        }

        
       `
    ]
})
export class AppTopBarComponent implements OnInit, OnDestroy {

    subscription: Subscription;
    notificationsdialogRef: DynamicDialogRef;
    notificationsCount: number;
    notificationsList: NotificationsModel[] = [];
    @ViewChild('overlayPanel') overlayPanel!: OverlayPanel;
    trackingDialogRef: DynamicDialogRef;
    operationType = [];
    selectedOperationType = null;

    items: any[] = [];
    filteredItems: any[] = [];

    firm: any;

    showSearch: boolean = false;
    invalidSearch: boolean = false;
    searchText: string = '';

    constructor(public app: AppComponent, public appMain: AppMainComponent, private router: Router,private translateService: TranslateService, private changeLanguageService: ChangeLanguageService, public viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver, private dialogService: DialogService, private notificationsService: NotificationsService) { }
    async ngOnInit() {

        this.firm = sessionStorage.getItem('firm');
       
        
        const appComponent = this.router.config.find(f => f.component.name == 'AppMainComponent');
        if (appComponent) {
            const routes = appComponent.children.filter(f => f.data && f.data.useSearch);
            routes.forEach(r => this.items.push({ name: r.data.title, value: r.path }));
        }

        this.showNotificationsCount();

        readNotify.subscribe(count => {
            this.notificationsCount = (this.notificationsCount - count) >= 0 ? (this.notificationsCount - count) : 0;
        })

        this.getSearchContainerOptions();

        this.changeLanguageService.languageChanged.subscribe((lang) => {
            this.getSearchContainerOptions();
        });


    }

    ngOnDestroy() {

        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        if (readNotify) readNotify.unsubscribe();
    }

    onSearch(event) {
        this.filteredItems = [];
        this.items.forEach(v => {
            if (v.name.toLocaleUpperCase('tr-TR').indexOf(event.query.toLocaleUpperCase('tr-TR')) === 0) this.filteredItems.push(v);
        });
        return this.filteredItems;
    }

    onHandleSearchSelect(event) {
        this.router.navigate([event.value]);
    }

    showChangePasswordDialog() {
        let factory = this.componentFactoryResolver.resolveComponentFactory(ChangePasswordComponent);
        let componentRef = this.viewContainerRef.createComponent(factory);
        componentRef.instance.display = true;
    }

    showNotificationsDialog() {
        this.notificationsdialogRef = this.dialogService.open(NotificationsComponent,
            { header: null, showHeader: false, modal: true, width: '800px', height: '800px', closable: false, data: {} });
    }

    async showNotificationsCount() {
        this.notificationsList = await this.notificationsService.getAllNotifications().toPromise();
        if (this.notificationsList && this.notificationsList.length > 0) {
            let count = this.notificationsList.filter(f => !f.readTime).length;
            this.notificationsCount = count;
        }

    }

    openSearch() {
    this.showSearch = true;
    }
    
    performSearch() {
    if(!this.searchText) {
        this.invalidSearch = true;
        return;
    }
    this.resetView();



    this.trackingDialogRef = this.dialogService.open(TrackingeComponent, {  header: this.searchText,  width: '100%',  height: '100vh', contentStyle: {"overflow": "auto"}, modal:true,  closable: true,  data: {searchText: this.searchText} });

    }

    resetView() {
        if (this.overlayPanel) this.overlayPanel.hide(); 
        this.showSearch = false;
    }

    async getSearchContainerOptions() {
        const select = await this.translateService.get("selectEmpty").toPromise();
        const airWay = await this.translateService.get("airWay").toPromise();        
        const seaWay = await this.translateService.get("seaWay").toPromise();
        const truckWay = await this.translateService.get("truckWay").toPromise();
        this.operationType = [{name: select, code: null}, {name: seaWay, code: 'SEA'},{name: airWay, code: 'AIR', disabled: true},{name: truckWay, code: 'TRUCK', disabled: true}];
    }



}
