import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskName'
})
export class MaskNamePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return '';
    if (value.length <= 3) return value + '***';
    return value.substring(0, 3) + '***';
  }
}
