import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from "rxjs/operators";
import {AuthenticationService} from './user/authentication.service';
import { errorMessagerSubject$ } from "./error-handler.service";


export const getToken = (): any => {
    if (!localStorage.getItem('token')) {
        return null;
    }
    return {token: localStorage.getItem('token'), sessionId: localStorage.getItem('sessionId')};
};

@Injectable()
export class TokenInterceptor implements HttpInterceptor {


    constructor(public auth: AuthenticationService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (localStorage.getItem("token")) {
            var token = localStorage.getItem("token");

            /* token varken tekrar login olmak istendiğinde backend'de sessionId alamıyor. Bu yüzden login endpoint e istek gönderildiğinde token eklenmiyor. */
            if(request.url.includes('/auth/login') || request.url.includes('/auth/reset') || request.url.includes('/public/fixedSearch/') || request.url.includes('/public/api/files/')) {
                return next.handle(request).pipe();
            }

           /* TODO:: Cookie göndermiyor backend e sessionı bulmak için attığımız takladan kurtulmak için request header a cookie göndermeyi çözülmeli... */
            request = request.clone({
                withCredentials: false,
               // responseType: 'json',
                setHeaders: {
                    'Authorization': 'Bearer ' + token,
                    //'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": 'true',
                    "Access-Control-Allow-Methods": '*',
                    "Access-Control-Allow-Headers": '*',
                    "Set-Cookies": `Cookie=JSESSIONID=${localStorage.getItem("sessionId")}`,
                    "Cookies": `JSESSIONID=${localStorage.getItem("sessionId")}`,
                    "uid": `${localStorage.getItem("uid")}`,
                }
            });
            return next.handle(request).pipe(
                catchError((error: HttpErrorResponse) => {

                    let msg = error?.message;
                    if (error.error?.message) {
                        msg = error?.error?.message || JSON.parse(error?.error)?.message
                    }

                    errorMessagerSubject$.next(error)
                    return throwError(msg);
                 })
            )
        } else {
            if(request.url.includes('/public/api/files/')) {
                return next.handle(request).pipe();
            }
            request = request.clone({
                withCredentials: false,
               // responseType: 'json',
                setHeaders: {
                    "uid": `${localStorage.getItem("uid")}`,
                }
            });


            return next.handle(request);
        }
    }

}



