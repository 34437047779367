import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { OperationInfo, SeaDocumentInfo } from 'src/app/models/upload-operation/operation-info';
import { OperationInfoService } from 'src/app/services/upload-operation/operation-info.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent {
  uuid1: string;
  uuid2: string;
  openMenu: boolean = false;
  operationInfo: OperationInfo;
  seaOperationInfo: SeaDocumentInfo
  responseMessage: string | null = null;
  
  constructor(private route: ActivatedRoute, protected operationInfoService: OperationInfoService, private sanitizer: DomSanitizer) {

  }
  
  async ngOnInit(): Promise<void> {
    this.uuid1 = this.route.snapshot.paramMap.get('uuid1') || '';
    this.uuid2 = this.route.snapshot.paramMap.get('uuid2') || '';
    
    this.route.paramMap.subscribe(params => {
      this.uuid1 = params.get('uuid1') || '';
      this.uuid2 = params.get('uuid2') || '';
    });

    await this.fetchOperationInfo(this.uuid1, this.uuid2);
  }
  
  async fetchOperationInfo(uuid1: string, uuid2: string): Promise<void> {
    try {
      this.operationInfo = await this.operationInfoService.getOperationInfo(uuid1, uuid2).toPromise();
      this.seaOperationInfo = this.operationInfo.seaDocumentInfo;
    } catch (e) {
      console.log(e);
    }
  }
  
  toggleMenu() {
    this.openMenu = !this.openMenu;
  }

  cardOpen: string = "";
  
  toggleCard(name: string) {
    if(name === this.cardOpen) {
      this.cardOpen = "";
    } else {
      this.cardOpen = name;
    }
  }

  popup: boolean = false;
  data: any = { };

  togglePopup() {
    if (this.popup) {
      this.data = {};
      this.popup = false;
    } else {
      this.data = { name: "Test" };
      this.popup = true;
    }
  }

  closePopup() {
    this.data = {};
    this.popup = false;
  }

  download() {
    window.print();
  }

  openPoup(doc: any): void {
    this.operationInfoService.getDocumentById(doc.awsId).subscribe(
      (blob) => {
        const url = URL.createObjectURL(blob);
        this.data = {
          file: this.sanitizer.bypassSecurityTrustResourceUrl(url),
          fileType: blob.type,
          name: doc.docTypeName,
        };
        this.popup = true;
      },
      (error) => {
        console.error('Belge yüklenirken hata oluştu:', error);
      }
    );
  }

  upload(doc: any): void {
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = (event: any) => {
      const file: File = event.target.files[0];
      if (file) {
        const uuid1 = this.uuid1;
        const uuid2 = this.uuid2;
        const docTypeId = doc.docTypeId;

        this.operationInfoService.uploadFile(uuid1, uuid2, docTypeId, file).subscribe({
          next: (response) => {
            console.log("response",response);
            window.location.reload();
          },
          error: (error) => {
            console.error('Dosya yükleme sırasında hata oluştu:', error);
          }
        });
      }
    };
    input.click();
  }

  delete(doc: any): void {
    const uuid1 = this.uuid1;
    const uuid2 = this.uuid2;
    const docTypeId = doc.docTypeId;
    this.operationInfoService.deleteFile(doc.awsId,uuid1, uuid2, docTypeId).subscribe({
      next: (response) => {
        console.log(response);
        window.location.reload();
      },
      error: (error) => {
        console.error('Dosya silme sırasında hata oluştu:', error);
      }
    });
  }

  onApprove(): void {
    this.operationInfoService.approve('tr', this.uuid1, this.uuid2).subscribe({
      next: (response) => {
        console.log(response);
        this.responseMessage = response;
        alert(this.responseMessage);
      },
      error: (error) => {
        console.log(error.error);
        this.responseMessage = error.error || 'Bir hata oluştu, lütfen tekrar deneyin.';
        alert(this.responseMessage);
      },
    });
  }
}
