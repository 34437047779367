import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { BaseService } from "../base.service";
import { environment } from "src/environments/environment";



@Injectable()
export class TrackingService extends BaseService<any>{
    constructor(public httpClient: HttpClient) {
        super(
            'user',
            httpClient);
    }


    tracking(containerNo: string): Observable<any> {
        let uri = new URL(environment.baseUrl + `/sea/tracking/${containerNo}`);

        return this.httpClient.get<any>(uri.toString(),  { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }

    trackingShipment(shipmentNo: string): Observable<any> {
        let uri = new URL(environment.baseUrl + `/sea/trackingShipment/${shipmentNo}`);

        return this.httpClient.get<any>(uri.toString(),  { withCredentials: false, headers: this.httpHeaders() })
            .pipe(
                tap(),
                catchError(this.handleError)
            )
    }
    


}
